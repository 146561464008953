<template>
  <v-container class='py-2 mt-2' style='max-width:1000px;float:left;'>

    <v-card :disabled='loading' :loading='loading' class='pa-4 mb-4'>

        <v-textarea
          outlined auto-grow rows=2 hide-details label='Tag Configuration (YAML)'
          v-model='tags' @change='saveTags'
          style='font-family:monospace;'
        />

        <v-textarea
          outlined auto-grow rows=2 hide-details label='Title Configuration (YAML)'
          v-model='titles' @change='saveTitles'
          class='mt-8' style='font-family:monospace;'
        />

    </v-card>


  </v-container>
</template>

<style lang="scss" scoped>
.dragMe{
  cursor: move;
}
</style>


<script>
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'Template',
  components: {
  },
  data(){ return {
    loading: false,
    tags: null,
    titles: null,
  }},
  computed: {
  },
  watch: {
    $route: { handler(){ this.SET_TITLE('Measure Options'); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", "PROMPT"]),
    ...mapActions("API", ["API"]),

    refresh(){
      this.loading = true;
      this.loadTags()
      .then(this.loadTitles)
      .finally(() => { this.loading = false; });
    },

    loadTags(){
      return this.API({ endpoint: 'measures/tags' })
      .then(tags => { this.tags = tags; })
    },

    loadTitles(){
      return this.API({ endpoint: 'measures/titles' })
      .then(titles => { this.titles = titles; })
    },

    saveTitles(yaml){
      this.loading = true;
      return this.API({ method: 'POST', endpoint: 'measures/titles', data: { yaml } })
      //.then(()=>{ this.$refs.preview.refresh(); })
      .finally(() => { this.loading = false; });
    },

    saveTags(yaml){
      this.loading = true;
      return this.API({ method: 'POST', endpoint: 'measures/tags', data: { yaml } })
      //.then(()=>{ this.$refs.preview.refresh(); })
      .finally(() => { this.loading = false; });
    },

  },
  mounted(){
    this.refresh();
  }
}
</script>
