var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "py-2 mt-2",
    staticStyle: {
      "max-width": "1000px",
      "float": "left"
    }
  }, [_c('v-card', {
    staticClass: "pa-4 mb-4",
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-textarea', {
    staticStyle: {
      "font-family": "monospace"
    },
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "2",
      "hide-details": "",
      "label": "Tag Configuration (YAML)"
    },
    on: {
      "change": _vm.saveTags
    },
    model: {
      value: _vm.tags,
      callback: function callback($$v) {
        _vm.tags = $$v;
      },
      expression: "tags"
    }
  }), _c('v-textarea', {
    staticClass: "mt-8",
    staticStyle: {
      "font-family": "monospace"
    },
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "2",
      "hide-details": "",
      "label": "Title Configuration (YAML)"
    },
    on: {
      "change": _vm.saveTitles
    },
    model: {
      value: _vm.titles,
      callback: function callback($$v) {
        _vm.titles = $$v;
      },
      expression: "titles"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }